<template>
    <v-navigation-drawer permanent mini-variant app mobile-breakpoint="600" mini-variant-width="65" :class="dimmedSidebar && 'dim'">
        <div class="d-flex flex-column pa-0">
            <v-sheet height="48" color="primary" class="d-flex align-center">
                <v-list-item dense :to="{ name: 'home-dashboard', params: { oid: $route.params.oid }, query: $route.query }">
                    <v-list-item-icon>
                        <img src="@/assets/N-Nuvolos-blue.svg" />
                    </v-list-item-icon>
                    <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item>
            </v-sheet>

            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-on="on"
                        dense
                        :to="{
                            name: 'snapshot-overview',
                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid },
                            query: $route.query
                        }"
                        e2e-sidebar-overview>
                        <v-list-item-icon>
                            <v-icon>{{ sidebarItems.DASHBOARD.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ sidebarItems.DASHBOARD.title }}</v-list-item-title>
                    </v-list-item>
                </template>
                <span>{{ sidebarItems.DASHBOARD.title }}</span>
            </v-tooltip>

            <div v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE">
                <v-tooltip v-if="!isInstanceObserver" right>
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            dense
                            v-on="on"
                            :to="{
                                name: 'snapshot-assignments',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid },
                                query: $route.query
                            }"
                            e2e-sidebar-assignments>
                            <v-list-item-icon>
                                <v-icon>assignment</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Assignments</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>Assignments</span>
                </v-tooltip>
                <v-list-item v-else>
                    <v-list-item-icon>
                        <v-icon disabled>assignment</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </div>
            <div class="shepherd-space-management">
                <v-menu
                    :close-on-content-click="true"
                    offset-x
                    v-model="manageMenu"
                    max-width="340"
                    open-on-hover
                    :close-delay="closingDelay"
                    e2e-sidebar-settings>
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" dense>
                            <v-list-item-icon>
                                <v-icon>{{ sidebarItems.SETTINGS.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ sidebarItems.SETTINGS.title }}</v-list-item-title>
                        </v-list-item>
                    </template>
                    <SettingsMenu />
                </v-menu>
                <v-divider></v-divider>
            </div>
            <div class="shepherd-space-files">
                <v-tooltip v-if="!isInstanceObserver" right>
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            dense
                            v-on="on"
                            :to="{
                                name: 'snapshot-files',
                                params: {
                                    oid: $route.params.oid,
                                    sid: $route.params.sid,
                                    iid: $route.params.iid,
                                    snid: $route.params.snid,
                                    localPath: currentFileLocalPath,
                                    fileArea: fileAreaType
                                },
                                query: $route.query
                            }"
                            @click="clearPathArray"
                            e2e-sidebar-files>
                            <v-list-item-icon>
                                <v-icon>{{ sidebarItems.FILES.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ sidebarItems.FILES.title }}</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>{{ sidebarItems.FILES.title }}</span>
                </v-tooltip>
                <v-list-item v-else>
                    <v-list-item-icon>
                        <v-icon disabled>{{ sidebarItems.FILES.icon }}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </div>
            <div class="shepherd-space-tables">
                <v-tooltip v-if="!isInstanceObserver" right>
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            v-on="on"
                            dense
                            :to="{
                                name: 'snapshot-tables',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid },
                                query: $route.query
                            }"
                            e2e-sidebar-tables>
                            <v-list-item-icon>
                                <v-icon>{{ sidebarItems.TABLES.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ sidebarItems.TABLES.title }}</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>{{ sidebarItems.TABLES.title }}</span>
                </v-tooltip>
                <v-list-item v-else>
                    <v-list-item-icon>
                        <v-icon disabled>{{ sidebarItems.TABLES.icon }}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </div>
            <div class="shepherd-space-apps">
                <v-tooltip v-if="!isInstanceObserver" right>
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            v-on="on"
                            dense
                            :to="{
                                name: 'snapshot-applications',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid },
                                query: $route.query
                            }"
                            e2e-sidebar-apps>
                            <v-list-item-icon>
                                <v-icon>{{ sidebarItems.APPLICATIONS.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ sidebarItems.APPLICATIONS.title }}</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>{{ sidebarItems.APPLICATIONS.title }}</span>
                </v-tooltip>
                <v-list-item v-else>
                    <v-list-item-icon>
                        <v-icon disabled>{{ sidebarItems.APPLICATIONS.icon }}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-divider></v-divider>
            </div>
            <div class="shepherd-space-snapshots">
                <v-menu
                    v-if="!isInstanceObserver && !isTrialSpace"
                    :close-on-content-click="true"
                    offset-x
                    v-model="snapshotMenu"
                    max-width="300"
                    open-on-hover
                    :close-delay="closingDelay">
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            dense
                            v-on="on"
                            :input-value="$route.path.includes('manage-snapshots')"
                            :to="{
                                name: 'instance-snapshots',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid },
                                query: $route.query
                            }"
                            e2e-sidebar-snapshots>
                            <v-list-item-icon>
                                <v-icon>{{ sidebarItems.SNAPSHOTS.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>snapshot actions</v-list-item-title>
                        </v-list-item>
                    </template>
                    <v-list nav dense>
                        <v-btn
                            block
                            color="primary"
                            class="justify-start mb-1"
                            elevation="0"
                            :to="{
                                name: 'instance-snapshots',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid },
                                query: $route.query
                            }"
                            e2e-sidebar-view-timeline>
                            <v-icon small class="mr-1">timeline</v-icon>
                            View snapshot timeline
                        </v-btn>
                        <v-list-item
                            v-if="currentSpaceType !== spaceTypes.VENDOR_SPACE && isDevelopment && isInstanceEditor"
                            @click="addSnapshot(snapshotTypes.QUICK_SNAPSHOT)"
                            :disabled="isSpaceArchived">
                            <v-list-item-title class="subtitle-2 d-flex align-center">
                                <v-icon small class="mr-1">mdi-camera-plus-outline</v-icon>
                                Quick snapshot
                            </v-list-item-title>
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small v-on="on" color="grey lighten-1">mdi-information</v-icon>
                                    </template>
                                    <span>This will create and save a snapshot of your current state, including all your files, tables, and applications.</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item
                            v-if="isDevelopment && isInstanceEditor"
                            :to="{
                                name: 'snapshot-add-snapshot',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                            }"
                            :disabled="isSpaceArchived"
                            e2e-sidebar-take-snapshot>
                            <v-list-item-title class="subtitle-2 d-flex align-center">
                                <v-icon small class="mr-1">mdi-camera-plus</v-icon>
                                Take snapshot and describe
                            </v-list-item-title>
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon small v-on="on" color="grey lighten-1">mdi-information</v-icon>
                                    </template>
                                    <span>Create a snapshot of your current state and provide detailed description.</span>
                                </v-tooltip>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-tooltip v-else right>
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on">
                            <v-list-item-icon>
                                <v-icon disabled>{{ sidebarItems.SNAPSHOTS.icon }}</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </template>
                    <span v-if="isTrialSpace">
                        You discovered a premium feature!
                        <br />
                        Subscribing to Nuvolos will unlock it.
                    </span>
                    <span v-else>Snapshots are disabled in this space</span>
                </v-tooltip>
            </div>
            <div class="shepherd-staging-step-3 shepherd-space-stage shepherd-assignment-step-1" @click="stageClicked">
                <v-tooltip right v-if="!isInstanceObserver">
                    <template v-slot:activator="{ on }">
                        <v-list-item
                            v-on="on"
                            dense
                            :to="{
                                name: 'snapshot-distribute',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid },
                                query: $route.query
                            }"
                            e2e-sidebar-sharing>
                            <v-list-item-icon>
                                <v-badge :color="numberOfStagedObjects > 0 ? 'primary' : 'secondary'" overlap class="caption" v-model="showBadge" bottom>
                                    <template v-slot:badge>
                                        <v-fab-transition>
                                            <span v-text="numberOfStagedObjects" fab></span>
                                        </v-fab-transition>
                                    </template>
                                    <v-icon>{{ sidebarItems.DISTRIBUTION.icon }}</v-icon>
                                </v-badge>
                            </v-list-item-icon>
                            <v-list-item-title>distribution</v-list-item-title>
                        </v-list-item>
                    </template>
                    <span>Distribution</span>
                </v-tooltip>
                <v-list-item dense v-else>
                    <v-list-item-icon>
                        <v-icon disabled>{{ sidebarItems.DISTRIBUTION.icon }}</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </div>
            <div>
                <the-snapshot-side-bar-assignment-menu-item v-if="$route.query.grading && isSpaceAdmin" />
                <ApplicationMenu />
            </div>
        </div>
        <template v-slot:append>
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" dense @click="toggleSidebarBrightness">
                        <v-list-item-icon>
                            <v-icon>mdi-brightness-6</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Dim sidebar</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Dim sidebar</span>
            </v-tooltip>
        </template>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { snapshotEnums } from '@/mixins/snapshot'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { createQuickAndDetailedSnapshots } from '@/mixins/createSnapshot'
import Shepherd from 'shepherd.js'

const ApplicationMenu = () => import('../components/TheApplicationSidebarMenu.vue')
const SettingsMenu = () => import('../components/TheSnapshotSettingsMenu.vue')
const TheSnapshotSideBarAssignmentMenuItem = () => import('../components/TheSnasphotSideBarAssignmentMenuItem')

export default {
    components: { SettingsMenu, ApplicationMenu, TheSnapshotSideBarAssignmentMenuItem },
    mixins: [appTypeAndImageLink, enumsData, createQuickAndDetailedSnapshots, snapshotEnums],
    data() {
        return {
            fab: false,
            showBadge: false,
            snapshotMenu: false,
            manageMenu: false,
            closingDelay: 100,
            sidebarItems: {
                DASHBOARD: { icon: 'dashboard', title: 'Overview' },
                SETTINGS: { icon: 'settings', title: 'Settings' },
                FILES: { icon: 'folder', title: 'Files' },
                TABLES: { icon: 'mdi-table-large', title: 'Tables' },
                APPLICATIONS: { icon: 'desktop_windows', title: 'Applications' },
                SNAPSHOTS: { icon: 'camera_alt' },
                DISTRIBUTION: { icon: 'share', title: 'Distribute objects' }
            }
        }
    },
    methods: {
        stageClicked() {
            Shepherd.activeTour && Shepherd.activeTour.complete()
        },
        clearPathArray() {
            this.$store.dispatch('snapshotStore/setPathArray', [''])
        },
        toggleSidebarBrightness() {
            this.$store.commit('toggleSidebarBrightness')
        }
    },
    computed: {
        numberOfStagedObjects() {
            return this.stagingObjects.files.length + this.stagingObjects.tables.length + this.stagingObjects.applications.length
        },
        ...mapGetters('instanceStore', ['isInstanceEditor', 'isInstanceObserver']),
        ...mapGetters('appStore', ['runningDeployments', 'startingDeployments']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'currentSpaceType', 'isSpaceArchived', 'currentSpaceData', 'isTrialSpace']),
        ...mapGetters('snapshotStore', ['currentFileLocalPath', 'isDevelopment', 'developmentSnapshot']),
        ...mapState('snapshotStore', ['stagingObjects', 'fileAreaType']),
        ...mapState(['userInfo', 'dimmedSidebar'])
    },
    watch: {
        numberOfStagedObjects: {
            immediate: true,
            handler(nextValue, preValue) {
                if (nextValue !== preValue) {
                    this.showBadge = false
                    setTimeout(() => {
                        this.showBadge = nextValue > 0
                    }, 300)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.topMenu--override {
    top: 0px !important;
    height: 48px !important;
    border-bottom-left-radius: 0px;
}

.v-list-item--dense,
.v-list--dense .v-list-item {
    min-height: 44px;
}
.v-list-item--dense .v-list-item__icon,
.v-list--dense .v-list-item .v-list-item__icon {
    height: auto;
    margin-top: 12px;
    margin-bottom: 12px;
}

.dim:not(img) {
    filter: invert(100%) hue-rotate(180deg);
}
</style>
